// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Colors
$white: #fff !default;
$black: #000 !default;
$blue:  #5E74A9 !default;

///Fonts
$main-font: 'Rational Light', sans-serif !default;
$second-font: 'Rational Semi Bold', sans-serif !default;

///Main
$main-font-size: 16px !default;
$main-text-color: $blue;
$brand-color: $blue;
$max-container-width: 1600px !default;
$min-container-width: 320px !default;
$page-background: $white;


/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '../' !default;
