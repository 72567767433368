// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

/// Responsive media queries
/// @author Eugen Zhyzhykin
/// @param {Number} $value - Screen size
@mixin respond-from($value) {
  @media only screen and (min-width: $value + 'px') { @content; }
}

/// Responsive media queries
/// @author Eugen Zhyzhykin
/// @param {Number} $value - Screen size
@mixin respond-to($value) {
  @media only screen and (max-width: $value + 'px') { @content; }
}

/// Convert px to rem
/// @author Eugen Zhyzhykin
/// @param {String} $target - size in px
@function rem ($target, $context: $main-font-size) {
	@return ($target / $context) * 1rem;
}

@mixin text-hide() {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}
