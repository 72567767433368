// -----------------------------------------------------------------------------
// This file contains all styles related to the content of the site/application.
// -----------------------------------------------------------------------------

.content {
  .top-images {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 195px;
    @include respond-to(600) {
      margin-bottom: 95px;
    }

    img {
      display: block;
      width: 45%;
      margin: 0 15px 0 0;
      flex: 1;

      &:nth-child(2) {
        margin: 0 0 0 15px;
      }
    }
  }

  .text-block {
    margin-bottom: 85px;
    @include respond-to(600) {
      margin-bottom: 55px;
    }

    &.intro {
      margin-bottom: 160px;
      @include respond-to(600) {
        margin-bottom: 50px;
      }
    }

    &.accordeon {
      margin-bottom: 35px;
    }

    .subtitle {
      line-height: 1.5em;
      display: block;
    }

    .toggle {
      position: relative;
      display: inline-block;
      padding-right: 26px;
      cursor: pointer;
      margin: 0;

      &.active {
        &:after {
          background-image: image('arrow-blue-up.svg');
        }
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 14px;
        width: 14px;
        height: 5px;
        background: image('arrow-blue-down.svg') 0 0 no-repeat;
      }
    }

    .toggle-content {
      margin-bottom: 90px;

      p {
        margin: 1.5em 0 0 0;
      }

      &.hidden {
        display: none;
      }
    }
  }

  .product-selector {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    margin-bottom: 66px;
    @include respond-to(1023) {
      flex-wrap: wrap;
    }
    @include respond-to(600) {
      padding-top: 0;
    }

    &.quickpad {
      margin-bottom: 0;
      @include respond-to(600) {
        display: block;
      }

      .col-right {
        width: 50%;
        flex: 1;
        @include respond-to(600) {
          width: 100%;
        }
      }
    }

    &.more {
      padding-top: 0;
      margin-bottom: 44px;
      margin-top: -22px;
      display: block;
      @include respond-to(600) {
        margin-top: 20px;
      }

      .show-more {
        display: inline-block;
        margin-bottom: 50px;
        position: relative;
        padding-right: 26px;
        text-decoration: none;
        color: $main-text-color;

        &:hover {
          color: $blue;
        }

        &.active {
          &:after {
            background-image: image('arrow-blue-up.svg');
          }
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          right: 0;
          top: 7px;
          width: 14px;
          height: 5px;
          background: image('arrow-blue-down.svg') 0 0 no-repeat;
        }
      }

      .more-content {
        display: none;
      }

      .two-col {
        margin-bottom: 50px;
        column-gap: 130px;
      }
    }

    .col-left {
      width: 50%;
      padding-right: 15px;
      flex: 1;
      @include respond-to(600) {
        width: 100%;
        padding: 0;
        display: block;
        order: 3;
        margin-bottom: 30px;
      }

      .wrap {
        padding-right: 0px;
        @include respond-to(600) {
          padding: 1px;
        }
      }
    }

    .col-right {
      width: 25%;
      padding-left: 15px;
      display: flex;
      justify-content: space-between;
      @include respond-to(1023) {
        width: 50%;
      }
      @include respond-to(600) {
        width: 100%;
        padding: 0;
        display: block;
      }

      &.no-flex {
        display: block;
      }

      .wrap {
        padding-left: 0px;
        @include respond-to(600) {
          padding-left: 1px;
        }
      }

      .product-nav {
        padding: 0;
        margin: 0;
        list-style: none;
        width: 90%;
        @include respond-to(600) {
          margin: 0;
          max-width: none;
          width: 100%;
        }

        li {
          cursor: pointer;
          padding: 0;
          margin-bottom: 14px;
          border-bottom: solid 1px transparent;

          &.active {
            color: $black;
            border-bottom: solid 1px $black;
          }
        }
      }
    }

    .product-description {
      width: 25%;
      @include respond-to(1023) {
        width: 100%;
        margin-top: 30px;
      }
      @include respond-to(600) {
        margin-bottom: 30px;
      }

      .descr {
        display: none;
        line-height: 1.5em;

        &.active {
          display: block;
        }
      }
    }

    .ico-360 {
      cursor: pointer;

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        bottom: 36px;
        width: 73px;
        height: 26px;
        transform: translateX(-50%);
        z-index: 10;
        background: image('ico-360.svg') 0 0 no-repeat;
      }
    }
  }
}
