// -----------------------------------------------------------------------------
// This file contains all styles related to the main navigation.
// -----------------------------------------------------------------------------
nav {
  position: absolute;
  background: $page-background;
  right: 20px;
  top: 28px;
  display: none;
  z-index: 100;
  color: $blue;
  @include respond-to(600) {
    right: 0;
    width: 100%;
    padding: 0 10px;
  }

  ul {
    padding: 10px 0;
    margin: 0;
    list-style: none;
    @include respond-to(600) {
      padding-top: 30px;
    }

    li {
      padding: 4px 10px;

      .active {
        text-decoration: underline;
      }
    }
  }
}

.btn-nav {
  width: 44px;
  height: 26px;
  padding: 0;
  border: none;
  outline: none;
  font-size: 0;
  position: absolute;
  top: 0;
  right: 20px;
  cursor: pointer;
  background: none;

  .hamburger-box {
    width: 44px;
    height: 26px;
    display: inline-block;
    position: relative;
    top: -1px;

    &.active {
      .hamburger-inner {
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::before {
          top: 0;
          opacity: 0;
          transition: top 0.075s ease,
                      opacity 0.075s 0.12s ease;
        }

        &::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.075s ease,
                      transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }

    .hamburger-inner {
      display: block;
      top: 50%;
      margin-top: 0;
      transition-duration: 0.075s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      width: 44px;
      height: 1px;
      background-color: $blue;
      position: absolute;
    }

    .hamburger-inner::before, .hamburger-inner::after {
      content: "";
      display: block;
    }

    .hamburger-inner::before {
      top: -13px;
      transition: top 0.075s 0.12s ease,
                  opacity 0.075s ease;
    }

    .hamburger-inner::after {
      bottom: -13px;
      transition: bottom 0.075s 0.12s ease,
                  transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }
}
