/**
 * Basic typography style for copy text
 */
body, html {
  color: $main-text-color;
  font: normal 125% / 1.4 $main-font;
  font-size: $main-font-size;
}

h1, h2 {
  font-size: 24px;
  font-weight: normal;
  line-height: 1.5em;
  margin: 0 0 1.5em 0;
}

b {
  font-family: $second-font;
  font-weight: normal;
}

p {
  line-height: 1.5em;
}

.two-col {
  column-count: 2;
  column-gap: 30px;

  @include respond-to(600) {
    column-count: 1;
  }

  ul {
    list-style: none;
    margin: 0 0 2em 0;
    padding: 0 0 0 2em;
    line-height: 1.5em;

    li {
      &:before {
        display: inline-block;
        content: '-';
        width: 2em;
        margin-left: -2em;
      }
    }
  }
}

.small {
  font-size: 14px;
  line-height: 1.8em;
}

ul.list {
  list-style: none;
  margin: 0 0 2em 0;
  padding: 0 0 0 2em;
  line-height: 1.5em;

  li {
    &:before {
      display: inline-block;
      content: '-';
      width: 2em;
      margin-left: -2em;
    }
  }
}
