// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
header {
  padding: 30px 0 100px 0;
  @include respond-to(600) {
    padding-bottom: 50px;
  }

  .container {
    position: relative;
  }

  .logo {
    display: block;

    img {
      max-width: 290px;
      @include respond-to(600) {
        max-width: 200px;
      }
    }
  }
}
