// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
  font-family: 'Rational Light';
  src: font('37A0B3_0_0.eot');
  src: font('37A0B3_0_0.eot?#iefix') format('embedded-opentype'),
       font('37A0B3_0_0.woff2') format('woff2'),
       font('37A0B3_0_0.woff') format('woff'),
       font('37A0B3_0_0.ttf')  format('truetype');
}

@font-face {
  font-family: 'Rational Semi Bold';
  src: font('37A0B3_1_0.eot');
  src: font('37A0B3_1_0.eot?#iefix') format('embedded-opentype'),
       font('37A0B3_1_0.woff2') format('woff2'),
       font('37A0B3_1_0.woff') format('woff'),
       font('37A0B3_1_0.ttf')  format('truetype');
}
